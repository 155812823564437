import BaseService from "./BaseService";


export default class TranslationService extends BaseService {
    public getTranslations() {
        return this.client.get('/api/v1/translations');
    }

    public getDatabaseCatalogTranslations(id: number) {
        return this.client.get(`/api/v1/database-catalog/${id}/translations`);
    }

    public getDataViewTranslations() {
        return this.client.get('/api/v1/data-view/translations');
    }

    public modifyTranslation(langCode: string, key: string, message: string, databaseCatalogId?: number, databaseCatalogGroupId?: number, dataViewCatalogId?: number, catalogFieldId?: number) {
        const req: any = {key, value: message};
        if (databaseCatalogId) {
            req.databaseCatalogId = databaseCatalogId;
        } else if (databaseCatalogGroupId) {
            req.databaseCatalogGroupId = databaseCatalogGroupId;
        } else if (dataViewCatalogId) {
            req.dataViewCatalogId = dataViewCatalogId;
        }
        if (catalogFieldId) {
            req.catalogFieldId = catalogFieldId;
        }
        return this.client.post(`/api/v1/translation/${langCode}/modify`, req);
    }

    public addTranslationLanguage(langCode: string) {
        return this.client.post(`/api/v1/translation/${langCode}/add-language`, {});
    }

    public deleteTranslationLanguage(langCode: string) {
        return this.client.delete(`/api/v1/translation/${langCode}`);
    }

    public publishTranslations(lang) {
        let data: any = {};
        data.lang = lang;
        return this.client.post('/api/v1/translations/publish', data);
    }

    public pullFromGlobalTranslations() {
        return this.client.post('/api/v1/translations/pull', {});
    }

    public publishTranslation(langCode: string, key: string, message: string = null) {
        let data: any = {key};
        if (message !== null) {
            data.message = message;
        }
        return this.client.post(`/api/v1/translation/${langCode}/publish`, data);
    }

    public getTranslationsConfig() {
        return this.client.get('/api/v1/translations/config');
    }

}