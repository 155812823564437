import React, {Component} from 'react';
import {Redirect, Route, Switch} from 'react-router-dom';
import {connect} from 'react-redux';
import {IntlProvider} from 'react-intl'
import AppLocale from '../lngProvider';

import MainApp from 'app/index';
import SignIn from './SignIn';
import {setInitUrl} from '../actions/Auth';
import asyncComponent from "appUtil/asyncComponent";
import {NotificationContainer} from "react-notifications";
import ModalContainer from "containers/ModalContainer";
import * as moment from "moment";
import * as QueryString from "query-string"
import {ToastContainer, toast} from 'react-toastify';
import {switchLanguage} from "actions";
import analyticsTracker from "../../ts/utils/analyticsTracker";
import _ from "lodash";


const RestrictedRoute = ({component: Component, authUser, ...rest}) =>
    <Route
        {...rest}
        render={props =>
            authUser
                ? <Component {...props} />
                : <Redirect
                    to={{
                        pathname: '/signin',
                        search: QueryString.stringify({
                            backUrl: window.location.href
                        }),
                        state: {from: props.location}
                    }}
                />}
    />;
const appLocalePattern = /^\/app\/(\w+)\//;

class App extends Component {

    constructor(props) {
        super(props);
        this.defaultErrorHandler = this.defaultErrorHandler.bind(this);
        this.registerModal = this.registerModal.bind(this);
        let urlLang = null;
        const lm = appLocalePattern.exec(this.props.location.pathname);
        if (lm && AppLocale[lm[1]]) {
            urlLang = lm[1];
        }
        this.state = {
            loading: false,
            urlLang
        };
        this.invokeTrackPageView = _.debounce(this.invokeTrackPageView.bind(this), 1000);
    }

    componentDidMount() {
        if (this.props.initURL === '') {
            this.props.setInitUrl(this.props.history.location.pathname);
        }
        if (analyticsTracker) {
            this.invokeTrackPageView();
        }
    }

    invokeTrackPageView() {
        analyticsTracker.trackPageView(this.props.location.pathname, document.title);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.location.pathname !== this.props.location.pathname) {
            document.title = this.props.baseTitle;
            if (analyticsTracker) {
                analyticsTracker.trackPageView(this.props.location.pathname);
            }
            const lm = appLocalePattern.exec(this.props.location.pathname);
            if (lm) {
                const overrideAppLocale = AppLocale[lm[1]];
                if (overrideAppLocale) {
                    if (overrideAppLocale.locale !== this.props.locale.locale) {
                        this.props.switchLanguage(overrideAppLocale);
                    }
                    if (this.state.urlLang !== overrideAppLocale.locale) {
                        this.setState({urlLang: overrideAppLocale.locale});
                    }
                } else if (this.state.urlLang) {
                    this.setState({urlLang: null});
                }
            } else if (this.state.urlLang) {
                this.setState({urlLang: null});
            }
        }
        if (prevProps.locale !== this.props.locale) {
            this.props.settingsEventBus.emit('locale-changed');
            if (this.state.urlLang && prevProps.location.pathname === this.props.location.pathname && this.state.urlLang !== this.props.locale.locale) {
                const newPath = this.props.location.pathname.replace(appLocalePattern, `/app/${this.props.locale.locale}/`);
                if (this.props.location.pathname !== newPath) {
                    this.props.history.replace(newPath);
                    this.props.history.location.state = {locale: this.props.locale.locale};
                    this.setState({urlLang: this.props.locale.locale});
                }
            }
        }
    }

    render() {
        const {match, location, locale, authUser, initURL, isDirectionRTL} = this.props;
        const {urlLang} = this.state
        if (location.pathname.trim() === '/') {
            if (authUser === null) {
                return (<Redirect to={'/signin'}/>);
            } else if (initURL === '' || initURL === '/app' || initURL === '/signin') {
                return (<Redirect to={'/app'}/>);
            }
            return (<Redirect to={'/app'}/>);
        }

        // for RTL Support
        if (isDirectionRTL) {
            document.documentElement.setAttribute("dir", "rtl");
        } else {
            document.documentElement.setAttribute("dir", "ltr");
        }
        const currentAppLocale = AppLocale[locale.locale];
        moment.locale(currentAppLocale.locale === 'ar' ? 'ar-ly' : currentAppLocale.locale);
        let prefixAppPath = `${match.url}app`;
        if (urlLang) {
            prefixAppPath += `/${urlLang}`;
        }
        return (
            <IntlProvider
                locale={currentAppLocale.locale}
                messages={currentAppLocale.messages}
                onError={this.defaultErrorHandler}
            >
                <div className="app-main">
                    <NotificationContainer/>
                    <ToastContainer/>
                    <ModalContainer reference={this.registerModal}/>
                    <Switch>
                        {/*<RestrictedRoute path={`${match.url}app`} authUser={authUser}*/}
                        {/*                 component={MainApp}/>*/}

                        <Route path={prefixAppPath} component={MainApp}/>
                        <Route path='/signin' component={SignIn}/>
                        <Route component={asyncComponent(() => import('app/routes/extraPages/routes/404'))}/>
                    </Switch>
                </div>
            </IntlProvider>
        );
    }

    defaultErrorHandler(message, e) {
        const matched = /\s\"([\w|\.|-|-]+)"/.exec(message);
        if (matched != null) {
            const key = matched[1];
            if (!key.startsWith('db.') && !key.startsWith('dataView.') && !key.startsWith('dbGroup.')) {
                AppLocale[this.props.locale.locale].messages[key] = key;
            }
        } else {
            console.error(message);
        }
    }

    registerModal(modal) {
        this.props.modalService.registerModal(modal);
    }
}

const mapStateToProps = ({settings, auth, services}) => {
    const {locale, isDirectionRTL, eventBus: settingsEventBus, baseTitle} = settings;
    const {authUser, initURL} = auth;
    const modalService = services.modal;
    return {locale, isDirectionRTL, authUser, initURL, modalService, settingsEventBus, baseTitle}
};

export default connect(mapStateToProps, {setInitUrl, switchLanguage})(App);
