import React from 'react';
import RouterBase from "app/routes/RouterBase";


export default class UserRoles extends RouterBase {

    get className() {
        return 'app-wrapper user-roles-rights-management';
    }

    get routes() {
        return [
            {
                path: '/dashboard',
                asyncComponent: () => import('./dashboard'),
                requiredRoles: ['super-admin', 'data-rights', 'database-rights']
            }
        ]
    }
}