import IAnalyticsTracker from "./IAnalyticsTracker";
import ReactGA from 'react-ga4';

export default class ReactGATracker implements IAnalyticsTracker {
    constructor(trackingId: string) {
        ReactGA.initialize(trackingId);
    }

    trackEvent<T>(category: string, action: string, label?: string, value?: T): void {
        ReactGA.event({
            category,
            action,
            label,
            value: typeof value === 'number' ? value : undefined, // GA accepts only number type for 'value'
        });
    }

    trackPageView(path: string, title?: string): void {
        ReactGA.send({hitType: 'pageview', page: path, title});
    }
}