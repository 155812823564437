import IAnalyticsTracker from "./IAnalyticsTracker";

let analyticsTracker: IAnalyticsTracker = null;

// @ts-ignore
if (GA_TRACKING_ID) {
    const ReactGATracker = require("./ReactGATracker").default;
    // @ts-ignore
    analyticsTracker = new ReactGATracker(GA_TRACKING_ID);
}

export default analyticsTracker;